import React from "react";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialLinks from "../components/SocialLinks";
const IndexPage = () => (
  <Layout
    title="Política de Privacidade"
    description="Conheça a nossa Política de Privacidade"
  >
    <Header
      frontPage={false}
      title="Política de Privacidade"
      smallSubtitle=" "
    />
    <div className="content pt-5">
      <div className="container pt-5">
        <div className="row justify-content-md-center">
          <Fade>
            <div className="col-sm-12 text-color">
              <h3 className="p-5">
                SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO ?{" "}
              </h3>
              <p>
                {" "}
                Quando realiza alguma transação, como parte do processo de
                compra e venda, recolhemos as informações pessoais que nos dá
                tais como: nome, e-mail, morada ,Número de
                contribuinte,Nacionalidade, data de nascimento, profissão e
                cargo, método preferido de pagamento; canal preferido de
                contacto e nome da empresa. . Quando acede ao nosso site, também
                recebemos automaticamente o protocolo de internet do seu
                computador, endereço de IP, a fim de obter informações que nos
                ajudam a aprender sobre seu navegador e sistema operativo.Email
                Marketing será realizado apenas caso permita. Nestes emails
                poderá receber notícias sobre nós, novos produtos e outras
                atualizações.
              </p>
              <h3 className="p-5"> SEÇÃO 2 - CONSENTIMENTO</h3>
              <p>
                {" "}
                Como obtêm meu consentimento ? Quando fornece informações
                pessoais como nome, telefone e endereço, para completar uma
                transação, verificar seu cartão de crédito, fazer um pedido,
                providenciar uma entrega ou retornar uma compra.Após a
                realização de ações entendemos que está de acordo com a recolha
                de dados para serem utilizados pela nossa empresa.Se pedimos as
                suas informações pessoais por uma razão secundária, como
                marketing, vamos pedir-lhe diretamente por seu consentimento, ou
                fornecer-lhe a oportunidade de dizer "não". Caso queira retirar
                o seu consentimento, como proceder ? Se após nos fornecer os
                seus dados mudar de ideia, poderá retirar o seu consentimento
                para que possamos entrar em contato, para a coleção de dados
                contínua, uso ou divulgação de suas informações, a qualquer
                momento, entrando em contato conosco.
              </p>
              <h3 className="p-5">SEÇÃO 3 - DIVULGAÇÃO</h3>
              <p>
                {" "}
                Podemos divulgar as suas informações pessoais caso sejamos
                obrigados pela lei para fazê-lo ou se violar os nossos Termos de
                Serviço.
              </p>
              <h3 className="p-5"> SEÇÃO 4 - SERVIÇOS DE TERCEIROS </h3>
              <p>
                {" "}
                No geral, os fornecedores terceirizados usados por nós irão
                apenas recolher, usar e divulgar as suas informações na medida
                do necessário para permitir que eles realizem os serviços que
                eles nos fornecem. Entretanto, certos fornecedores de serviços
                terceirizados, tais como gateways de pagamento e outros
                processadores de transação de pagamento, têm suas próprias
                políticas de privacidade com respeito à informação que somos
                obrigados a fornecer para eles de suas transações relacionadas
                com compras. Para esses fornecedores, recomendamos que leia as
                suas políticas de privacidade para que possa entender a maneira
                na qual as suas informações pessoais serão usadas por esses
                fornecedores. Em particular, lembre-se que certos fornecedores
                podem ser localizados em/ou possuir instalações que são
                localizadas em jurisdições diferentes.Assim, se quer continuar
                com uma transação que envolve os serviços de um fornecedor de
                serviço terceirizado, então as suas informações podem tornar-se
                sujeitas às leis da(s) jurisdição(ões) nas quais o fornecedor de
                serviço ou suas instalações estão localizados.Como um exemplo,
                se está localizado no Canadá e sua transação é processada por um
                gateway de pagamento localizado nos Estados Unidos, então as
                suas informações pessoais usadas para completar aquela transação
                podem estar sujeitas a divulgação sob a legislação dos Estados
                Unidos, incluindo o Ato Patriota.Uma vez que deixe o site ou
                seja redirecionado para um aplicativo ou site de terceiros, não
                será mais regido por essa Política de Privacidade ou pelos
                Termos de Serviço do nosso site. Links quando clica em links,
                eles podem lhe direcionar para fora do nosso site.Não somos
                responsáveis pelas práticas de privacidade de outros sites e lhe
                incentivamos a ler as declarações de privacidade deles.
              </p>
              <h3 className="p-5"> SEÇÃO 5 - SEGURANÇA</h3>
              <p>
                {" "}
                Para proteger suas informações pessoais, tomamos precauções
                razoáveis e seguimos as melhores práticas da indústria para nos
                certificar que elas não serão perdidas inadequadamente,
                usurpadas, acessadas, divulgadas, alteradas ou destruídas.Se nos
                fornecer as suas informações de cartão de crédito, essa
                informação é criptografada usando tecnologia "secure socket
                layer"(SSL) e armazenada com uma criptografia AES - 256. Embora
                nenhum método de transmissão pela Internet ou armazenamento
                eletrônico é 100 % seguro, nós seguimos todos os requisitos da
                PCI - DSS e implementamos padrões adicionais geralmente aceitos
                pela indústria.
              </p>
              <h3 className="p-5">
                {" "}
                SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
              </h3>
              <p>
                {" "}
                Reservamos o direito de modificar essa política de privacidade a
                qualquer momento, então por favor, revise - a com
                frequência.Alterações e esclarecimentos vão surtir efeito
                imediatamente após sua publicação no site.Se fizermos alterações
                de materiais para essa política, iremos notificá-lo aqui que
                eles foram atualizados, para que tenha ciência sobre quais
                informações coletamos, como as usamos, e sob que circunstâncias,
                se alguma, usamos e / ou divulgamos elas.Se formos adquiridos ou
                fundirmos com outra empresa, suas informações podem ser
                transferidas para os novos proprietários para que possamos
                continuar a vender produtos/serviços.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
