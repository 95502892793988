import React from 'react';
import config from '../../config';
import { StaticQuery, graphql } from "gatsby";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faWordpress,
  faTelegram,
  faWhatsapp,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import {
  faLink,
  faEnvelope,
  faHeart,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Zoom from "react-reveal/Zoom";

import "../assets/sass/_social.scss";

const shareButtonsIcons = [
  faLink,
  faHeart,
  faInstagram,
  faEnvelope,
  faPhone,
  faFacebookF,
  faLinkedinIn,
  faYoutube,
  faWordpress,
  faTelegram,
  faWhatsapp,
  faPinterest
];

const icons = {
  facebook: ["fab", "facebook-f"],
  linkedin: ["fab", "linkedin-in"],
  instagram: ["fab", "instagram"],
  telegram: ["fab", "telegram"],
  email: ["fas", "envelope"],
  phone: ["fas", "phone"],
  pintrest: ["fab", "pinterest"],
  whatsapp: ["fab", "whatsapp"],
};
library.add(...shareButtonsIcons);
export default function SocialLinks({ sectionP=true, links = config.socialLinks}) {
  const socialLinks = (
    <StaticQuery
      query={graphql`
        query {
          wpPage(isFrontPage: { eq: true }) {
            id
            layout {
              instagram
              facebook
              telegram
              whatsapp
              phone
              pintrest
              linkedin
            }
          }
          wp {
            generalSettings {
              email
            }
          }
        }
      `}
      render={(data) => {
        const socialLinks = {
          ...data.wp.generalSettings,
          ...data.wpPage.layout,
        };
        return Object.keys(socialLinks)
          .filter((k) => socialLinks[k])
          .map((key) => (
            <Zoom key={socialLinks[key]}>
              <a
                href={socialLinks[key].replace(/ /g, "")}
                className={`mx-2`}
                rel="noopener noreferrer"
                aria-label={key}
                target="_blank"
                title={key}
              >
                <FontAwesomeIcon icon={icons[key]} />
              </a>
            </Zoom>
          ));
      }}
    />
  );
  
  return sectionP ? (
    <section className="social-section bg-fg">
      <div className="social d-flex justify-content-center">{socialLinks}</div>
    </section>
    ) : (<span className="social">{socialLinks}</span>)
  ;
}
