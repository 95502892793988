import React from 'react';
import Logo from '../Logo';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import config from "../../../config";
import "./footer.scss";
import Loadable from "@loadable/component";

const SocialLinks = Loadable(() => import("../SocialLinks"));

library.add(...[faHeart]);
export default function Footer() {
  return (
    <footer className="bg-fg small text-center text-white-50 pt-5">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <Logo type="color" />
          </div>
        </div>
        <div className="row">
          <div className="col text-center p-3 pt-3"></div>
        </div>
        <div className="row">
          <div className="col text-center p-3">
            <a
              title="Cookies"
              href={`${config.url}${config.pathPrefix}cookies/`}
            >
              {" "}
              Cookies{" "}
            </a>
            <a
              title="Politica Privacidade"
              href={`${config.url}${config.pathPrefix}privacidade/`}
            >
              {" "}
              Politica Privacidade{" "}
            </a>
            <a title="termos" href={`${config.url}${config.pathPrefix}termos/`}>
              {" "}
              Termos{" "}
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <SocialLinks sectionP={false} />
          </div>
        </div>

        <div className="row">
          <div className="col text-center p-5">
            Made with
            <FontAwesomeIcon icon={"heart"} /> by
            <a
              title="EMMIM - Coworking de terapeutas"
              href="https://www.emmim.pt/"
            >
              EMMIM®
            </a>
            <p>
              Copyright © {new Date().getFullYear()}. Todos os direitos
              reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
